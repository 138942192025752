import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FingerprintService {
    private storageKey = 'browser_fingerprint';

    constructor() { }

    // Método público para obter ou gerar o fingerprint
    public getOrCreateFingerprint(username: string): string {
        username = username.split('@')[0];

        // 1. Verificar no localStorage
        let fingerprint = localStorage.getItem(this.storageKey);
        if (fingerprint) {
            return `${username}-${fingerprint}`;
        }

        // 2. Gerar um novo fingerprint
        this.generateFingerprint().then(fingerprintResult => {
            fingerprint = fingerprintResult;
            this.saveFingerprint(fingerprint);
            return `${username}-${fingerprint}`;
        });
    }

    // Método privado para salvar o fingerprint no localStorage
    private saveFingerprint(fingerprint: string): void {
        localStorage.setItem(this.storageKey, fingerprint);
    }

    // Método privado para gerar um fingerprint
    private async generateFingerprint(): Promise<string> {
        const data = [
            navigator.userAgent,
            navigator.platform,
            navigator.vendor,
            navigator.language,
            new Date().getTimezoneOffset().toString()
        ].join('|');

        return this.hashString(data);
    }

    // Método para criar um hash (SHA-256)
    private async hashString(data: string): Promise<string> {
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(data);

        // A API `crypto.subtle.digest` retorna um `PromiseLike`, convertemos para uma `Promise`
        const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);

        // Converta o resultado em uma string hexadecimal
        return Array.from(new Uint8Array(hashBuffer))
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    }
}
